import { RouteRecordRaw } from 'vue-router';

// @ts-ignore
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    meta: {
      renderMenu: false
    },
    component: () => import('@/components/layout/FrontView.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {
          icon: 'LoginOutlined',
          view: 'blank',
          target: '_blank',
          cacheable: false,
        },
        component: () => import('@/pages/login'),
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
  {
    path: '/workplace',
    name: '工作台',
    meta: {
      icon: 'DashboardOutlined',
      cacheable: false,
    },
    component: () => import('@/pages/workplace'),
  },
  {
    path: '/salesman',
    name: '业务员列表',
    meta: {
      icon: 'StrikethroughOutlined',
      cacheable: false, renderMenu: true
    },
    component: () => import('@/pages/salesman'),
  },
  {
    path: '/users',
    name: '注册用户',
    meta: {
      icon: 'StrikethroughOutlined',
      cacheable: false, renderMenu: true
    },
    component: () => import('@/pages/user'),
  },
  {
    path: '/number',
    name: '杠杆交易记录',
    meta: {
      icon: 'StrikethroughOutlined',
      cacheable: false, renderMenu: true
    },
    component: () => import('@/pages/number/order'),
  },
  {
    path: '/pledge',
    name: '定存交易记录',
    meta: {
      icon: 'StrikethroughOutlined',
      cacheable: false, renderMenu: true
    },
    component: () => import('@/pages/pledge'),
  },
  {
    path: '/exchange',
    name: '兑换交易记录',
    meta: {
      icon: 'StrikethroughOutlined',
      cacheable: false, renderMenu: true
    },
    component: () => import('@/pages/counter/order'),
  },
  {
    path: '/exchange-flash',
    name: '闪兑交易记录',
    meta: {
      icon: 'StrikethroughOutlined',
      cacheable: false, renderMenu: true
    },
    component: () => import('@/pages/counter/flash'),
  },
  {
    path: '/wallet',
    name: '用户钱包记录',
    meta: {
      icon: 'StrikethroughOutlined',
      cacheable: false, renderMenu: true
    },
    component: () => import('@/pages/wallet'),
  }
];

export default routes;
